html,
body,
#root,
#root > div {
    width: 100%;
    height: 100%;
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

*,
:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

input {
    outline: 0;
}

@font-face {
    font-family: Montserrat-Bold;
    src: url(static/fonts/montserrat-latin-700.woff2) format("woff2");
    src: url(static/fonts/montserrat-latin-700.woff) format("woff");
}

@font-face {
    font-family: NunitoSans-Regular;
    src: url(static/fonts/nunito-latin-300.woff2) format("woff2");
    src: url(static/fonts/nunito-latin-300.woff) format("woff");
}

@font-face {
    src: url(static/fonts/MaterialIcons.ttf);
    font-family: MaterialIcons;
}

@font-face {
    src: url(static/fonts/FontAwesome6_Pro_Thin.ttf);
    font-family: FontAwesome6_Pro_Thin;
    font-weight: 100;
    font-style: normal;
}

@font-face {
    src: url(static/fonts/FontAwesome6_Pro_Light.ttf);
    font-family: FontAwesome6_Pro_Light;
    font-weight: 300;
    font-style: normal;
}

@font-face {
    src: url(static/fonts/FontAwesome6_Pro_Regular.ttf);
    font-family: FontAwesome6_Pro_Regular;
    font-weight: 400;
    font-style: normal;
}

@font-face {
    src: url(static/fonts/FontAwesome6_Pro_Brands.ttf);
    font-family: FontAwesome6_Pro_Brands;
    font-weight: 400;
    font-style: normal;
}

@font-face {
    src: url(static/fonts/FontAwesome6_Pro_Duotone.ttf);
    font-family: FontAwesome6_Pro_Duotone;
    font-weight: 900;
    font-style: normal;
}

@font-face {
    src: url(static/fonts/FontAwesome6_Pro_Solid.ttf);
    font-family: FontAwesome6_Pro_Solid;
    font-weight: 900;
    font-style: normal;
}

@font-face {
    src: url(static/fonts/FontAwesome6_Pro_Solid_Sharp.ttf);
    font-family: FontAwesome6_Pro_Sharp_Solid;
    font-weight: 900;
    font-style: normal;
}

@font-face {
    src: url(static/fonts/MaterialCommunityIcons.ttf);
    font-family: MaterialCommunityIcons;
}
